.default {
    --primary-100: #DFE7E7;
    --primary-200: #209490;
    --primary-300: #0F4A48;
    --primary-400: #0F4A48;
    --primary-500: #06201F;
    --primary-600: #06201F;
    --primary-700: #06201F;
    --primary-800: #06201F;
    --primary-900: rgba(6, 32, 31, 0.8);

    --accent-100: #F9D3C0;
    --accent-200: #FF6501;
    --accent-300: #FF6501;
    --accent-400: #FF6501;
    --accent-500: #EA5E15;
    --accent-600: #EA5E15;
    --accent-700: #EA5E15;
    --accent-800: #EA5E15;
    --accent-900: #EA5E15;

    --secondary-100: #e6fffa;
    --secondary-200: #b2f5ea;
    --secondary-300: #81e6d9;
    --secondary-400: #4fd1c5;
    --secondary-500: #38b2ac;
    --secondary-600: #319795;
    --secondary-700: #2c7a7b;
    --secondary-800: #285e61;
    --secondary-900: #234e52;

    --neutral-100: #F4F6F6;
    --neutral-200: #F4F6F6;
    --neutral-300: #DFE7E7;
    --neutral-400: #8D9999;
    --neutral-500: #8D9999;
    --neutral-600: #3A3F3F;
    --neutral-700: #3A3F3F;
    --neutral-800: #3A3F3F;
    --neutral-900: #161038;

    --error-100: #cd0000;
    --error-200: #FFDDD3;
    --error-400: #DC2626;

    --light-100: #ffffff;
    --light-150: #F4F7F7;
    --light-200: rgba(22, 16, 56, 0.06);

    --highlight-100: #cdfedc;

    --text-color: #161038;
    --text-color-light: #FFFCFA;

    --swiper-theme-color: #0F4A48;
    --swiper-navigation-size: 20px;

    --button-hollow: #F4F6F6;
    --button-hollow-hover: #DFE7E7;
    --button-hollow-dark: #0F4A48;
    --button-hollow-text: #0F4A48;
  }
