.content-slider-wrapper {
  --swiper-button-size: 46px;
  --pagination-bullet-size: 15px;
}

.content-slider-wrapper .swiper-wrapper {
  padding: theme('space.2') 0;
}

.content-slider-wrapper .swiper-pagination {
  bottom: 0 !important;
}

.content-slider-wrapper .swiper-pagination .swiper-pagination-bullet {
  opacity: 1;
  width: var(--pagination-bullet-size);
  height: var(--pagination-bullet-size);
  background-color: theme('colors.primary.100');
}

.content-slider-wrapper .swiper-button-next,
.content-slider-wrapper .swiper-button-prev {
  display: none;
}

@media (min-width: 1024px) {
  .content-slider-wrapper .swiper-button-next,
  .content-slider-wrapper .swiper-button-prev {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--swiper-button-size);
    height: var(--swiper-button-size);
    top: theme('space.4');
    background-color: theme('colors.neutral.100');
    border-radius: 50%;
  }

  .content-slider-wrapper .swiper-button-next:after,
  .content-slider-wrapper .swiper-button-prev:after {
    font-size: 22px;
    color: theme('colors.blue.600');
  }
}

.content-slider-wrapper .swiper-button-prev {
  left: auto !important;
  right: theme('space.14');
}

.content-slider-wrapper .swiper-pagination-bullet-active {
  background-color: #0f4a48 !important;
}
