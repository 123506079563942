@tailwind base;
@tailwind components;
@tailwind utilities;

@import './themes/default.css';
@import './themes/theme1.css';
@import './themes/theme2.css';
@import './themes/theme3.css';

html {
  @apply p-0;
  /* For tailwind Dialog styling override */
}

body {
  color: var(--neutral-900);
}

/* This style corresponds to
 * the footer component due to
 * a bug in TailwindCSS not
 * recognizing template columns
 * with more than 4 columns.
 * see components/commercetools-ui/footer/index.tsx
 * This is a hardcoded and messy
 * workaround.
 */
@media (min-width: 768px) {
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

form input[type='checkbox']:checked,
form input[type='checkbox']:checked:hover,
form input[type='checkbox']:checked:focus {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='%23ffffff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3E%3C/svg%3E");
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  background-color: #0f4a48;
}

form input[type='checkbox']:checked:hover {
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.select-accent {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9J25vbmUnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggc3Ryb2tlPScjY2UzZTcyJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMS41JyBkPSdNNiA4bDQgNCA0LTQnLz48L3N2Zz4K');
}

/**
 * Since tailwind after:content-[] does not support '**' we simply use our own classes here ...
 */
.tax-rate-regular::after {
  content: '*';
}

.tax-rate-diff::after {
  content: '**';
}

.no-scrollbar::-webkit-scrollbar {
  /* Chrome, Safari, Opera */
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
}

@layer base {
  html {
    font-family: 'Poppins', sans-serif;
  }
}

@layer utilities {
  .relative-width {
    width: 100%;
    left: 0;
    right: 0;
    margin-left: 0px;
    margin-right: 0px;
  }

  .fixed-screen-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  .markdown {
    @apply max-w-none overflow-x-hidden;
    @apply mx-auto pb-10 text-neutral-800 prose-h1:text-neutral-800 prose-h2:text-neutral-800 prose-h3:text-neutral-800 prose-h4:text-neutral-800;
    @apply prose-a:text-accent-600 prose-blockquote:text-neutral-700;
    @apply prose-code:rounded prose-code:bg-neutral-200 prose-code:p-1 prose-code:text-accent-600 prose-pre:bg-neutral-200;
    @apply prose-img:rounded-sm;
    @apply prose-strong:font-bold prose-strong:text-neutral-800;
    @apply prose-ol:list-decimal;
    @apply prose-ul:list-disc;
    @apply prose-li:text-neutral-800;
  }
}
