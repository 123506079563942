.revelo-slider-product {
  --swiper-pagination-bullet-width: 16px;
  --swiper-pagination-bullet-height: 16px;
  --swiper-pagination-bullet-horizontal-gap: 2px;
  --swiper-pagination-color: #0f4a48;
  --swiper-pagination-bullet-inactive-color: #dfe7e7;
  --swiper-pagination-bullet-inactive-opacity: 1;
}

.revelo-slider-product .swiper-pagination-bullet.slider__bullet--active {
  background-color: #0f4a48;
}

.revelo-slider-product-outer,
.revelo-slider-product-outer__thumbs {
  position: static !important;
  display: grid;
  width: calc(100% - 100px);
}

.revelo-slider-product .swiper-button-next {
  right: 0;
}

.revelo-slider-product .swiper-button-prev {
  left: 0;
}

.revelo-slider-product .swiper-button-next,
.revelo-slider-product .swiper-button-prev {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.revelo-slider-product .swiper-button-next:after,
.revelo-slider-product .swiper-button-prev:after {
  font-size: 32px;
}

.revelo-slider-product .swiper-button-next:hover,
.revelo-slider-product .swiper-button-prev:hover {
  background-color: #f4f6f6;
}

.revelo-slider-product .swiper-horizontal > .swiper-pagination-bullets,
.revelo-slider-product .swiper-pagination-bullets.swiper-pagination-horizontal,
.revelo-slider-product .swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
}
