/* General */
.revelo-slider-v2,
.revelo-slider-v2__thumbs {
  --swiper-theme-color: #161038;
  --swiper-navigation-sides-offset: 0;
  --swiper-navigation-size: 20px;
  --neutral-100: #f4f6f6;
}

.slider__container {
  height: 100%;
}

.swiper-slide-zoomed .zoom-hint {
  opacity: 0;
}

.revelo-slider-v2,
.revelo-slider-v2 .swiper-slide {
  height: auto;
}

.revelo-slider-v2 img {
  width: 100%;
}

.revelo-slider-v2__thumbs .swiper-slide {
  border: 1px solid var(--primary-100);
}

.revelo-slider-v2__thumbs .swiper-slide-thumb-active {
  border: 1px solid var(--primary-400);
}

.revelo-slider-v2 .slider__bullet--active {
  background-color: var(--primary-400);
  opacity: 1;
}

/* Navigation */
.revelo-slider-v2 .swiper-button-prev,
.revelo-slider-v2__thumbs .swiper-button-prev {
  justify-self: start;
}

.revelo-slider-v2 .swiper-button-next,
.revelo-slider-v2__thumbs .swiper-button-next {
  justify-self: end;
}

.revelo-slider-v2 [class^='swiper-button-'],
.revelo-slider-v2__thumbs [class^='swiper-button-'] {
  height: calc(10px + var(--swiper-navigation-size));
  width: calc(10px + var(--swiper-navigation-size));
  border-radius: calc(5px + var(--swiper-navigation-size));
}

.revelo-slider-v2 [class^='swiper-button-']:hover,
.revelo-slider-v2 [class^='swiper-button-']:active,
.revelo-slider-v2__thumbs [class^='swiper-button-']:hover,
.revelo-slider-v2__thumbs [class^='swiper-button-']:active {
  background-color: var(--neutral-100);
  border-radius: calc(5px + var(--swiper-navigation-size));
}

.revelo-slider-v2 [class^='swiper-button-']:active,
.revelo-slider-v2__thumbs [class^='swiper-button-']:active {
  opacity: 0.5;
}

.revelo-slider-v2-inner [class^='swiper-button-'],
.revelo-slider-v2-inner__thumbs [class^='swiper-button-'] {
  background-color: white;
  opacity: 0.7;
}

.revelo-slider-v2-outer,
.revelo-slider-v2-outer__thumbs {
  width: calc(100% - 40px);
}

.modal-fullscreen-image .revelo-slider-v2 {
  height: 100% !important;
}

.modal-fullscreen-image .slider__slides {
  height: 85%;
}

.modal-fullscreen-image .slider__thumbs {
  padding: 0 8px;
  max-width: 660px;
  margin-left: auto;
  margin-right: auto;
}

.modal-fullscreen-image .swiper-slide-zoomed .swiper-zoom-container > img {
  cursor: zoom-out;
}
